<template>
    <div>
        <CompTable ref="comp_table" isSelector title="设置第三方" :columns="columns" table-api="/gateway/api/lfcommon/pc/appinfo/selectAppInfoPage">
            <template #operate>
                <Button type="info" @click="$refs.modal.display()">批量新增应用</Button>
                <Button type="error" @click="onBatchDelete">批量删除应用</Button>
            </template>
        </CompTable>

        <CompModal ref="modal" class="thirdparty-add" title="批量新增应用" :onSuccess="onSubmitAdd" @on-close="onCloseAddModal">
            <div class="attr-box">
                <div class="name-box">
                    <div class="name">应用名称</div>
                    <div class="name">链接方式</div>
                    <div class="name">应用包名 (appid)</div>
                    <div class="name" style="width:350px">链接</div>
                    <div class="operate-add" @click="onAddItem">
                        <Icon class="icon" type="md-add" color="#fff" />
                    </div>
                </div>
                <div class="item-box" v-for="(item, idx) in add_list" :key="item.item_id">
                    <div class="item-form">
                        <div class="item">
                            <Input class="module" v-model="item.appName" type="text" placeholder="输入应用名称" />
                        </div>
                        <div class="item">
                            <Select v-model="item.appType" class="module" filterable>
                                <Option value="1">小程序</Option>
                                <Option value="2">H5</Option>
                                <Option value="3">APP</Option>
                            </Select>
                        </div>
                        <div class="item">
                            <Input class="module" v-model="item.appId" type="text" placeholder="输入应用包名 (appid)" />
                        </div>
                        <div class="item" style="width:350px">
                            <Input class="module" v-model="item.page" type="text" placeholder="输入链接" style="width:350px" />
                        </div>
                    </div>
                    <div class="operate">
                        <div class="operate-item remove" @click="onRemoveItem(idx)">
                            <Icon class="icon" type="md-remove" color="#fff" />
                        </div>
                    </div>
                </div>
            </div>
        </CompModal>

        <CompForm ref="edit_form" :forms="edit_forms" id-key="appInfoId" edit-api="/gateway/api/lfcommon/pc/appinfo/update" @on-submit="$refs.comp_table.refresh()"></CompForm>
    </div>
</template>

<script>
import CompTable from "@/views/jointly/components/comp-table.vue"
import CompModal from "@/views/residentdatabase/components/CompModal.vue"
import Request from "../jointly/utils/request"
import CompForm from "../jointly/components/CompForm.vue"
import Route from "../communitymobilization/entity/Route"

export default {
    components: {
        CompTable,
        CompModal,
        CompForm,
    },

    data() {
        return {
            columns: [
                {
                    title: "应用名称",
                    key: "appName",
                },
                {
                    title: "链接方式",
                    render: (h, params) =>
                        ({
                            3: "APP",
                            2: "H5链接",
                            1: "小程序",
                        }[params.row.appType]),
                },
                {
                    title: "应用包名 (appId)",
                    key: "appId",
                },
                {
                    title: "链接",
                    key: "page",
                },
                {
                    fixed: "right",
                    title: "操作",
                    width: 280,
                    minWidth: 280,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            Route.jump("/thirdpartyitem", {
                                                p: params.row.appInfoId,
                                                t: params.row.appName,
                                            })
                                        },
                                    },
                                },
                                "业务点设置"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.$refs.edit_form.formData = {}
                                            this.$nextTick(()=>{
                                                this.$refs.edit_form.display(null, params.row)
                                                this.$refs.edit_form.detailId = params.row.appInfoId
                                            })
                                            
                                        },
                                    },
                                },
                                "修改"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "error",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.onDelete([params.row.appInfoId])
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ])
                    },
                },
            ],

            // 新增列表
            add_list: [{ item_id: this.$core.randomString() }],

            // 修改表单配置
            edit_forms: [
                {
                    type: "input",
                    title: "应用名称",
                    name: "appName",
                },
                {
                    type: "select",
                    title: "链接方式",
                    name: "appType",
                    options: [
                        {
                            value: "1",
                            label: "小程序",
                        },
                        {
                            value: "2",
                            label: "H5",
                        },
                        {
                            value: "3",
                            label: "APP",
                        },
                    ],
                },
                {
                    type: "input",
                    title: "应用包名 (appId)",
                    name: "appId",
                },
                {
                    type: "input",
                    title: "链接",
                    name: "page",
                },
            ],
        }
    },

    methods: {
        /**
         * 新增列表项
         */
        onAddItem() {
            this.add_list.push({
                item_id: this.$core.randomString(),
            })
        },

        /**
         * 删除列表项
         */
        onRemoveItem(idx) {
            this.add_list.splice(idx, 1)
            // 至少会显示一列空白
            if (this.add_list.length <= 0) {
                this.onAddItem()
            }
        },

        /**
         * 监听关闭新增弹窗
         */
        onCloseAddModal() {
            this.add_list = [
                {
                    item_id: this.$core.randomString(),
                },
            ]
        },

        /**
         * 提交新增
         */
        onSubmitAdd() {
            Request.post(
                "/gateway/api/lfcommon/pc/appinfo/batchAddAppInfo",
                this.add_list.map(v => {
                    delete v.item_id
                    return v
                }),
                {
                    json: true,
                }
            ).then(() => {
                this.$Message.success("新增成功")
                this.$refs.modal.close()
                this.$refs.comp_table.refresh()
            })
        },

        /**
         * 批量删除
         */
        onBatchDelete() {
            const s = this.$refs.comp_table.getSelection()

            if (s.length <= 0) {
                this.$Message.warning("请先选择要删除的应用")
                return
            }

            this.onDelete(s.map(v => v.appInfoId))
        },

        /**
         * 删除
         * @param {Array} ids
         */
        onDelete(ids) {
            this.$Modal.confirm({
                title: "提示",
                content: "当前删除操作将不可恢复，请谨慎操作，是否继续？",
                onOk: () => {
                    this.$post("/gateway/api/lfcommon/pc/appinfo/batchDelete", ids, {
                        "Content-Type": "application/json",
                    }).then(res => {
                        if (res.code == 200) {
                            this.$Message.success({
                                content: "删除成功",
                                background: true,
                            })
                            this.$refs.comp_table.refresh()
                        } else {
                            this.$Message.error({
                                content: res.desc || "删除失败",
                                background: true,
                            })
                        }
                    })
                },
            })
        },
    },
}
</script>

<style lang="less">
.thirdparty-add {
    .attr-box {
        margin: 0 10px;
        padding: 20px 0;

        .name-box {
            display: flex;
            align-items: flex-end;
            margin-bottom: 10px;

            .name {
                padding: 0 10px;
                width: 200px;
                margin: 0 10px;
                flex-shrink: 0;
            }

            .value-box {
                width: 300px;
            }

            .operate-add {
                cursor: pointer;
                margin: 0 10px;
                padding: 0 15px;
                font-size: 20px;
                border-radius: 4px;
                background: #6fc3f8;
            }
        }

        .item-box {
            margin-bottom: 10px;
            display: flex;

            .item-form {
                display: flex;
                flex-shrink: 0;
            }

            .item {
                margin: 0 10px;

                .module {
                    width: 200px;

                    &.input {
                        height: 32px;
                        line-height: 1.5;
                        padding: 4px 7px;
                        font-size: 14px;
                        border: 1px solid #dcdee2;
                        border-radius: 4px;
                        color: #515a6e;
                        background-color: #fff;
                    }
                }

                .range-box {
                    width: 200px;

                    .range-item {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .range {
                            width: 90px;
                        }
                    }
                }

                &.value-box {
                    width: 300px;

                    .module {
                        width: 300px;
                    }

                    .range-box {
                        width: 300px;

                        .range-item {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .range {
                                width: 140px;
                            }
                        }
                    }
                }
            }

            .operate {
                margin: 0 10px;

                .operate-item {
                    cursor: pointer;
                    padding: 0 15px;
                    font-size: 20px;
                    border-radius: 4px;

                    &.add {
                        background: #6fc3f8;
                    }

                    &.remove {
                        background: #f88662;
                    }
                }
            }
        }
    }
}
</style>
